@import "./src/common/constants/styleConstants";

$animation-time: 300ms;
$out-animation-time: 500ms;
.login_section, .registration_section{
  width: 100%;
  height: 100vh;
}
.login_area, .registration_area{
  padding: 30px;
  width: 20%;
  min-width: 300px;
  margin: auto;
  background-color: $main_color;
  border-radius: 30px;
}
.center_column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login_button{
  border-radius: 10px;
  width: 40%;
  height: 40px;
  font-size: 15px;
  margin-top: 10px;
}
.login_form{
  width: 70%;
}
.login_input{
  font-family: $main_font;
  width: calc(100% - 4px - 10px);
  height: 30px;
  padding: 2px;
  border-radius: 5px;
  border: $main_border;
  margin: 5px 0 5px 0;
  outline: none;
  background-color: #fff7e0;
}
.login_area h1,.registration_area h1{
  margin: 0 0 10px 0;
  font-family: $secondary_font;
  font-size: 30px;
}
.login_area form p, .registration_area form p{
  margin: 0;
}
.login_registration_div{
  font-family: $secondary_font;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  p{
    margin: 0;
  }
}
.sign_up_proposition{
  cursor: pointer;
  margin-left: 10px;
  color: $link_color;
  text-decoration: underline;
}
.login_trans-appear {
  opacity: 0;
}
.login_trans-appear-active {
  opacity: 1;
  transition: opacity $animation-time ease-in-out;
}
.login_trans-appear-done {
  opacity: 1;
}
.login_trans-exit {
  transform: translate(0, 0);
}
.login_trans-exit{
  transform: translate(0,0);
}
.login_trans-exit-active{
  transform: translate(0, 20px);
  transition: transform ease-out 200ms ;
}
.login_trans-exit-done{
  transform: translate(0, -100vh);
  transition: transform ease-in ($out-animation-time -100ms) ;
}
@media (max-width: 400px) {
  .login_area{
    width: calc(100%);
    border-left: 0;
    border-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .login_registration_div{
    flex-direction: column;
    text-align: center;
  }
  .sign_up_proposition{
    font-size: 25px;
  }
}