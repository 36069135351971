@import "src/common/constants/styleConstants";

body{
  background-color: $bg_color;
  font-family: $main_font;
}

.table_title{
  font-family: $secondary-font;
  font-size: 40px;
  background-color: inherit;
  border: none;
  color: $secondary_color;
  margin: 20px 0 30px 0;
  text-align: center;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  outline: none;
}
.table_title_div{
  margin: 0 auto 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}
.container{
  margin: 0 auto 0 auto;
  width: inherit;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  flex-direction: row;
  flex-wrap: wrap;
}
button, .add_list_label, .add_list_form{
  font-family: $main_font;
  width: 100%;
  height: 20%;
  font-size: 1.5em;
  background-color: #EDBF3B;
  padding: 5px 0 5px 0;
  border-radius: 40px;
  border: $main_border;
}
button:hover, .add_list:hover{
  background-color: #f1c763;
  cursor: pointer;
}
.button_column{
  display: flex;
  justify-content: flex-start;
  width: 300px;
  height: 500px;
  margin-left: 20px;
}
button.home{
  box-shadow: $main_shadow;
  min-height: 0;
  font-size: 1.2em;
  text-transform: capitalize;
  padding: 5px 10px 5px 10px;
  width: auto;
  height: auto;
  border-radius: 10px;
}
.board_section{
  min-height: 100vh;
  overflow: scroll;
}
.board_section::-webkit-scrollbar { width: 0 !important }
.link_home{
  right: -100px;
  position: absolute;
}
@media (max-width: 450px){
  .container{
    display: flex;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
  }
  .button_column{
    margin: 0 auto 0 auto;
    height: 150px;
  }
  .add_list_label{
    height: 70px;
  }
  .board_section .log_out{
    position: relative;
    margin: 20px auto 20px auto;
  }

}