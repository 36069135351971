@import "src/common/constants/styleConstants";
.add_card{
  background-color: #318BC6;
  border-radius:50%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  p{
    margin: -3px -1px 0 0;
    text-align: center;
    font-size: 30px;
  }
}
.card_creator{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  background-color: #EDBF3B;
  border-radius: 30px;
  .delete_button{
    display: block;
    position: relative;
    width: 15px;
    height: 15px;
    right: initial;
    top: initial;
    margin-left: 5px;
    border: 0;
    background-color: $delete_button_color;
  }
  .button_panel{
    margin-bottom: 5px;
  }
  button{
    border: 0;
  }
}
.create_card_textarea{
  background-color: #e8c76d;
  width: 80%;
  border-radius: 10px;
  outline: none;
  margin: 5px 0 10px 0;
  padding: 5px;
  resize: none;
}
.create_card_textarea::-webkit-scrollbar{
  width: 0;
}
.create_card_button{
  border: 3px solid black ;
  width: 120px;
  height: 30px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  background-color: #318BC6;
}
@media (max-width: 450px) {
  .create_card_textarea{
    height: 50px;
    font-size: 20px;
  }
  .create_card_button{
    width: 150px;
    height: 40px;
    font-size: 25px;
  }
  .card_creator .delete_button{
    width: 25px;
    height: 25px;
  }
}