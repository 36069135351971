@import "src/common/constants/styleConstants";
h6{
  font-size: 30px;
  margin: 0;
  letter-spacing: 1px;
}
.card_modal_area{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $card_modal_bg;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  animation: showUpCard 1s forwards;
}
.card_modal{
  padding: 50px;
  width: 750px;
  height: 500px;
  background-color: $card_color;
  border: 5px solid $bg_color;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 0 20px 2px black;
  p{
    margin: 10px 0 10px 0;
    font-size: 20px;
  }
  span{
    text-decoration: underline;
  }
  .delete_button{
    display: flex;
    width: 30px;
    height: 30px;
    margin: 30px;
  }
}

.participants_label{
  font-size: 22px;
  text-transform: uppercase;
}
.participants{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.participant, .add_participant{
  width: 50px;
  height: 50px;
  background-color: #ED8C6B;
  margin: 0 10px 0 0;
  border: var(--main-border);
  border-radius: 50px;
}
.add_participant, .join_button, .change_description{
  background-color: #e8c76d;
}
.add_participant{
  padding: 0;
  font-size: 30px;
}
.join_button{
  height: 50px;
  width: 100px;
  border-radius: 15px;
}
.description_label{
  font-size: 22px;
  font-weight: bold;
}
.description_input{
  width: calc(60% - 20px);
  height: 180px;
  resize: none;
  background-color: #c3d3ff;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  padding: 10px;
  outline: none;
}
.description_input_changed{
  border: 2px solid black;
  outline: initial;
}
.change_description{
  display: block;
  width: 100px;
  height: 50px;
  border-radius: 20px;
}
.actions{
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
}
.action{
  width: 250px;
  margin: 15px 0 15px 0;
}
.delete_action_button{
  background-color: #ED8C6B;
  &:hover{
    background-color: #f39a83;
  }
}
#lists, .option{
  color: white;
  background-color: #95b7ff;
  font-family: var(--main-font);
  font-size: 20px;
  text-align: center;
  height: 50px;
  border: var(--main-border);
  border-radius: 10px;
}
@media (max-width: 450px){

  .card_modal_area{
    width: 100vw;
  }
  .card_modal{
    height: 90vh;
    padding: 5%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .participant, .add_participant, .join_button, .participants_label{
    display: none;
  }
  .description_input{
    width: 90%;
    margin-bottom: 40px;
    height: 50vh;
    overflow: scroll;
  }
  .actions{
    display: flex;
    position: relative;
    transform: none;
    flex-direction: row;
    align-items: center;
    width: 100%;
    right: initial;
    top: initial;
  }
  #lists{
    width: 100%;
    top: 20px;
    position: absolute;
  }
  .option{
    width: 50%;
  }
  .action{
    margin-top: 100px;
    width: 100px;
    height: 50px;
  }
}