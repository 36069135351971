@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');

// common
$delete_button_color: #ff7676;
$bg_color: rgb(0, 86, 140);
$main_font: 'Quicksand', sans-serif;
$secondary_font: 'Amatic SC';
$main_border: 0px solid black;
$main_color: #EFD390FF;
$main_shadow: 0 3px 5px black;
$secondary_color: #ED8C6B;
$link_color: #318BC6;
//home
$home_title_color: #ffc400;
$board_creator_color: #b9cfe7;
//board
$card_color: #bbdffd;
$card_modal_bg: rgba(20,20,20,0.2);
$list_color: #ED693B;