@import "src/common/constants/styleConstants";
.add_list_label{
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: $main_shadow;
}
.add_list_label p{
  width: 100%;
  text-align: center;
}
.add_list_form{
  margin-top: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: $main_shadow;
  input{
    outline: none;
    background-color: #f1c763;
    border-radius: 15px;
    width: 85%;
    height: 35px;
  }
  .delete_button{
    display: block;
    position: initial;
    z-index: 2;
  }
}

.button_panel{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.add_list_submit{
  border: 2px solid black;
  font-size: 10px;
  height: 25px;
  background-color: #318BC6;
  width: 150px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  &:hover{
    background-color: #47a1ea;
  }
}
