@import "src/common/constants/styleConstants";

.home{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  user-select: none;
  .container {
    position: relative;
    display: grid;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
  }
}

a{
  text-decoration: none;
  color: black;
  z-index: 1;
  &:active{
    color: initial;
  }
  &:hover{
    div.board{
      background-color: #f1d591;
    }
    .delete_button{
      display: flex;
    }
  }
}

.home_title{
  font-family: $secondary_font;
  color: $home_title_color;
  font-size: 50px;
}

.add_board{
  animation: none;
  position: relative;
  font-size: 1em;
  background-color: $secondary_color;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
    background-color: #eaa78c;
  }
}
.board_creator_div{
  display: flex;
  flex-direction: column;
  align-items: center;

}
@media (max-width: 450px) {
  .home_title{
    margin: 20px 0 20px 0;
  }
  .home {
    .container{
      display: flex;
      flex-direction: column;
    }
  }
  .add_board{
    margin-bottom: 20px;
  }
  .log_out{
    position: relative;
    display: block;
    margin: 20px;
  }
}