.card_slot{
  width: 240px;
  background-color: #cb5533;
  min-height: 70px;
  border-radius: 20px;
  position: relative;
  box-shadow:  inset  1px 2px 3px black;
  margin: 0 0 20px 0;
  cursor: pointer;
}
