@import "src/common/constants/styleConstants";
.board_creator {
  width: 180px;
  height: 160px;
  padding: 20px;
  border: var(--main-border);
  border-radius: 15px;
  background-color: $board_creator_color;
  text-align: center;
  margin: 0 0 50px;
}

#new_board_naming {
  border: black solid 3px;
  border-radius: 15px;
  outline: none;
  width: calc(100% - 6px);
  height: 30%;
  font-size: 16px;
  font-weight: bold;
  overflow-y: scroll;
  -ms-overflow-style: none;
  background-color: inherit;
}

#new_board_naming::-webkit-scrollbar {
  width: 0;
}

h4 {
  margin: 0 0 10px 0;
}

[contenteditable=true]:empty:not(:focus):before {
  content: attr(data-ph);
  color: grey;
  font-style: italic;
}

.board_creator button {
  height: auto;
  margin-top: 15px;
  border: black solid 3px;
}
