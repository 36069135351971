@import "src/common/constants/styleConstants";
@keyframes rotation {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}
.loading{
  width: 100%;
  height: 100vh;
  background-color: $bg_color;
}
.display_center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.outer_circle{
  width: 100px;
  height: 100px;
  border: 10px solid $secondary_color;
  border-bottom-color: $main_color;
  border-radius: 50%;
  animation: rotation 1s linear infinite;
}
