@import "src/common/constants/styleConstants";

.list{
  position: relative;
  background-color: $list_color;
  height: fit-content;
  animation: showUp 1s forwards;
  margin: 10px 20px 40px 20px;
  width: 310px;
  min-height: 500px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 50px;
  box-shadow: $main_shadow;
  flex-direction: column;
  z-index: 0;
  .delete_button{
    background-color: var(--delete-color);
    right: -10px;
    display: none;
    top: -10px;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    z-index: 1;
  }
  &:hover{
    .delete_button{
      display: flex;
    }
  }
}
h2{
  text-align: center;
  color: black;
  background-color: #EDBF3B;
  width: 250px;
  height:35px;
  padding: 5px 0 5px 0;
  margin: 15px 15px 5px 15px;
  border-radius: 30px;
  outline: none;
  overflow: auto;
  &:hover{
    background-color: #f1c763;
  }
  &::-webkit-scrollbar{
    width: auto;
  }
}
@media (max-width: 450px) {
  .list{
    margin-bottom: 30px;
  }
}
