@import "src/common/constants/styleConstants";
.inner_text{
  font-size: 18px;
  resize: none;
  white-space: pre;
  width: calc(95% - 10px);
  height: calc(95% - 10px);
  border: 0;
  background-color: inherit;
  font-family: inherit;
  outline: none;
}

.inner_text::-webkit-scrollbar{
  width: 0;
}
.card p{
  padding: 0;
  margin: 7px 0 0 0;
  overflow: hidden;
}
.card_box{
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.card{
  box-shadow: $main_shadow;
  animation: showUpCard 0.5s forwards ease-in;
  width: 240px;
  padding: 0 2.5% 0 2.5%;
  margin: 0 0 20px 0;
  height: 70px;
  background-color: $card_color;
  border-radius: 20px;
  user-select: none;
  z-index: 0;

}
.edit, .del_card{
  display: none;
  right: -15px;
  bottom: -15px;
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 5px solid black;
  background-color: $bg_color;
  z-index: 5;
}
.del_card{
  right: -15px;
  top: -15px;
  background-color: $delete_button_color;

}
.hidden{
  display: none;
}
.dragImage{
  position: absolute;
  width: 230px;
  padding: 0 2.5% 0 2.5%;
}
.card_link{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
