@import "src/common/constants/styleConstants";
h3{
  margin: 0;
  font-size: 16px;
  text-decoration: none;
}
.board, button.board{
  position: relative;
  padding: 20px 20px 20px;
  display: flex;
  width: 180px;
  height: 80px;
  background-color: #EDCB6B;
  margin: 15px;
  border-radius: 15px;
  box-shadow: $main_shadow;
  cursor: pointer;
}
.delete_button{
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 20px;
  height: 15px;
  width: 15px;
  background-color: $delete_button_color;
  z-index: 50;
  &:hover{
    cursor: pointer;
  }
}