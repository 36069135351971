@import "src/common/constants/styleConstants";
.log_out{
  height: 48px;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: absolute;
  right: 20px;
  top: 26px;
  font-size: 15px;
  background-color: $secondary_color;
  box-shadow: $main_shadow;

}
.log_out_icon{
}
@media (max-width: 450px) {
  .log_out{
    top:initial;
    right: initial;
    bottom: 20px;
    margin: 0;
    width: 230px;
    padding: 0 20px 0 20px;
  }

}